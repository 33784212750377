import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Swiper from 'swiper/swiper-bundle.min.js';
import Toolbar from "@/components/toolbar/toolbar";
import 'swiper/swiper-bundle.min.css';

export default {
    name: "Compare",
    data() {
        return {
            index_compare: [1, 2, 3],
            imageSource: require('@/assets/img/build-color/building-img-color1.png'),
            type_building: [
                {
                    name: this.$t("compare.allBuilding"),
                    img: require("@/assets/img/findyourglass/build/build_1.png"),
                  },
                  {
                    name: this.$t("compare.lowBuilding"),
                    img: require("@/assets/img/findyourglass/build/build_3.png"),
                  },
                  {
                    name: this.$t("compare.highBuilding"),
                    img: require("@/assets/img/findyourglass/build/build_2.png"),
                  },
            ],
            list_protection: [
                { id: 1, name: "ปลอดภัย", img: require('@/assets/img/compare/i_protection/i_1.png') },
                { id: 2, name: "UV", img: require('@/assets/img/compare/i_protection/i_2.png') },
                { id: 3, name: "เสียง", img: require('@/assets/img/compare/i_protection/i_3.png') },
            ],
            lists_color1: [
                { id: 1, name_color: "gray-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 1, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 2, name_color: "gray-normal", img: require('@/assets/img/Home/example_glass_02.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "22", vlt: "10", vlr_out: "13", vlr_in: "15", det: "85", er: "43", ea: "48", sc: "0.24", shgc: "21", lsg: "0.21", u_value: "5.59", rhg: "6.38", price: "3" },
                { id: 3, name_color: "gray-light", img: require('@/assets/img/Home/example_glass_03.png'), type: 3, type_protection: [{ id: 1, name: "ปลอดภัย", value: false }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "5", vlt: "80", vlr_out: "83", vlr_in: "85", det: "55", er: "43", ea: "18", sc: "0.98", shgc: "76", lsg: "0.55", u_value: "3.59", rhg: "7.12", price: "1" },
                { id: 4, name_color: "white-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 5, name_color: "white-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 6, name_color: "sky-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 7, name_color: "green-lime", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 8, name_color: "sky-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 9, name_color: "orange-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 10, name_color: "green-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 11, name_color: "yellow-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 12, name_color: "orange-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 13, name_color: "purple-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 14, name_color: "black-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 15, name_color: "snow", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
            ],
            lists_color2: [
                { id: 1, name_color: "gray-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 1, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 2, name_color: "gray-normal", img: require('@/assets/img/Home/example_glass_02.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "22", vlt: "10", vlr_out: "13", vlr_in: "15", det: "85", er: "43", ea: "48", sc: "0.24", shgc: "21", lsg: "0.21", u_value: "5.59", rhg: "6.38", price: "3" },
                { id: 3, name_color: "gray-light", img: require('@/assets/img/Home/example_glass_03.png'), type: 3, type_protection: [{ id: 1, name: "ปลอดภัย", value: false }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "5", vlt: "80", vlr_out: "83", vlr_in: "85", det: "55", er: "43", ea: "18", sc: "0.98", shgc: "76", lsg: "0.55", u_value: "3.59", rhg: "7.12", price: "1" },
                { id: 4, name_color: "white-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 5, name_color: "white-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 6, name_color: "sky-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 7, name_color: "green-lime", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 8, name_color: "sky-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 9, name_color: "orange-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 10, name_color: "green-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 11, name_color: "yellow-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 12, name_color: "orange-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 13, name_color: "purple-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 14, name_color: "black-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 15, name_color: "snow", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
            ],
            lists_color3: [
                { id: 1, name_color: "gray-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 1, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 2, name_color: "gray-normal", img: require('@/assets/img/Home/example_glass_02.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "22", vlt: "10", vlr_out: "13", vlr_in: "15", det: "85", er: "43", ea: "48", sc: "0.24", shgc: "21", lsg: "0.21", u_value: "5.59", rhg: "6.38", price: "3" },
                { id: 3, name_color: "gray-light", img: require('@/assets/img/Home/example_glass_03.png'), type: 3, type_protection: [{ id: 1, name: "ปลอดภัย", value: false }, { id: 2, name: "UV", value: true }, { id: 3, name: "เสียง", value: true }], thickness: "5", vlt: "80", vlr_out: "83", vlr_in: "85", det: "55", er: "43", ea: "18", sc: "0.98", shgc: "76", lsg: "0.55", u_value: "3.59", rhg: "7.12", price: "1" },
                { id: 4, name_color: "white-dark", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 5, name_color: "white-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 6, name_color: "sky-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 7, name_color: "green-lime", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 8, name_color: "sky-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 9, name_color: "orange-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 10, name_color: "green-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 11, name_color: "yellow-light", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 12, name_color: "orange-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 13, name_color: "purple-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 14, name_color: "black-normal", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
                { id: 15, name_color: "snow", img: require('@/assets/img/Home/example_glass_01.png'), type: 2, type_protection: [{ id: 1, name: "ปลอดภัย", value: true }, { id: 2, name: "UV", value: false }, { id: 3, name: "เสียง", value: false }], thickness: "6", vlt: "22", vlr_out: "25", vlr_in: "29", det: "18", er: "20", ea: "62", sc: "0.40", shgc: "35", lsg: "0.20", u_value: "4.25", rhg: "5.21", price: "5" },
            ],
            current_color1: {},
            current_thickness1: {},
            current_color2: {},
            current_thickness2: {},
            current_color3: {},
            current_thickness3: {},
            type_product: [],
            product: [],
            all_thickness_glass: [],
            baseFront: this.$baseFront
        }
    },
    methods: {
        gotoPage(url){
            location.href = url;
        },
        fetchThickness(index,item) {
            if(index == 1) {
                this.current_thickness1 = item;
                this.current_color1 = this.product.filter(i=>i.ref_type_product_id == this.current_thickness1.ref_type_product_id && i.glass_thickness == this.current_thickness1.glass_thickness)[0]
            }
            if(index == 2) {
                this.current_thickness2 = item;
                this.current_color2 = this.product.filter(i=>i.ref_type_product_id == this.current_thickness2.ref_type_product_id && i.glass_thickness == this.current_thickness2.glass_thickness)[0]
            }
            if(index == 3) {
                this.current_thickness3 = item;
                this.current_color3 = this.product.filter(i=>i.ref_type_product_id == this.current_thickness3.ref_type_product_id && i.glass_thickness == this.current_thickness3.glass_thickness)[0]
            }
        },
        gotoDetail(id) {
            this.$router.push(`product-content/${id}`)
        },
        async fetchTypeProduct() {
            await this.$axios.get("/api/show/type_product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.type_product = response.data.data
                    }
                })
        },
        async fetchProduct() {
            await this.$axios.get("/api/show/product")
                .then((response) => {
                    if (response.data.code == 200) {
                        this.product = response.data.data
                        this.current_color1 = this.product.find(i=>i.id == 7);
                        this.current_color2 = this.product.find(i=>i.id == 21);
                        this.current_color3 = this.product.find(i=>i.id == 99);
                        const set = new Set(this.product.map(item => JSON.stringify({glass_thickness: item.glass_thickness,ref_type_product_id: item.ref_type_product_id})));
                        this.all_thickness_glass = Array.from(set).map(item => JSON.parse(item));
                        this.current_thickness1 = this.all_thickness_glass[0];
                        this.current_thickness2 = this.all_thickness_glass[0];
                        this.current_thickness3 = this.all_thickness_glass[0];
                    }
                })
        },
    },
    watch: {
        async current_color1(newValue) {
            this.current_thickness1 = this.all_thickness_glass.find(i=>i.glass_thickness == newValue.glass_thickness && i.ref_type_product_id == newValue.ref_type_product_id);
        },
        async current_color2(newValue) {
            this.current_thickness2 = this.all_thickness_glass.find(i=>i.glass_thickness == newValue.glass_thickness && i.ref_type_product_id == newValue.ref_type_product_id);
        },
        async current_color3(newValue) {
            this.current_thickness3 = this.all_thickness_glass.find(i=>i.glass_thickness == newValue.glass_thickness && i.ref_type_product_id == newValue.ref_type_product_id);
        },
    },
    async mounted() {
        window.scrollTo(0, 0);
        await this.fetchTypeProduct();
        await this.fetchProduct();
        if (this.$route.query.current_1) {
            this.current_color1 = this.product.find(i => i.id == this.$route.query.current_1);
        } if (this.$route.query.current_2) {
            this.current_color2 = this.product.find(i => i.id == this.$route.query.current_2);
        }
        if (this.$route.query.compare) {
            this.current_color1 = this.product.filter(i => i.ref_type_product_id == this.$route.query.compare)[0];
            console.log(this.current_color1);
        }
        document.title = "AGC - Compare";
        AOS.init();
        resize();
        function resize() {
            if (window.innerWidth < 800) $('.footer').css({ 'position': 'relative', 'top': '0px' });
        }
        // eslint-disable-next-line
        $('.m-search-BT').on('click', (evt) => {
            $('.m-search-BT + .dp').toggle()
        });
        $('img[alt="color"]').on('click', (evt) => {
            $('img[alt="color"].active').removeClass("active");
            $('#' + evt.target.id).addClass("active");
            // $('img[alt="building-img"]').attr('src',`/img/build-color/building-img-${evt.target.id}.png`); 
            this.imageSource = require(`@/assets/img/build-color/building-img-${evt.target.id}.png`)
        })
    },
    filters: {
        list_name_protection(list) {
            let store_name_active = []
            if (list.protect_1 == 1) {
                store_name_active.push(localStorage.locale == 'th' ?'ปลอดภัย':'Safety')
            } if (list.protect_2 == 1) {
                store_name_active.push('UV')
            } if (list.protect_3 == 1) {
                store_name_active.push(localStorage.locale == 'th' ?'ปลอดภัย':'Sound')
            }
            return store_name_active.join("/");
        },
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    }
};